import Parse from 'parse';

export const markInvoicePaidUnPaid = async ({
  invoiceRemoteId,
  paid = false,
  paymentMethodValue
}: {
  invoiceRemoteId: string;
  paid?: boolean;
  paymentMethodValue?: string;
}) => {
  try {
    await Parse.Cloud.run(`markPaymentsPaidOrUnpaid`, {
      invoiceRemoteId,
      paid,
      paymentMethodValue
    });
  } catch (error) {
    console.error('Error while getting payments from Parse', error);
  }
};
